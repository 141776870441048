import React, { useState, useEffect } from 'react'
import { createHook } from 'react-sweet-state'

import IndexPage from '../components/IndexPage'

import Store from '../components/Store.js'
const useLanguage = createHook(Store)

const IndexPageDe = () => {
  const [state, actions] = useLanguage()
  actions.set('de')

  return (
    <>
      <IndexPage />
    </>
  )
}

export default IndexPageDe
